import { IconButton } from '@packages/sk8/button'
import React from 'react'

import ArrowLeft from 'icons/bold/01-Interface Essential/40-Keyboard/keyboard-arrow-left.svg'

export interface PreviousButtonProps {
  onClick: () => void
}

const PreviousButton = ({ onClick }: PreviousButtonProps) => (
  <IconButton
    variant="text"
    type="button"
    className="hover:-translate-x-0.5 transition-transform"
    iconClassName="h-6 w-6 mr-2 fill-primary-500"
    Icon={ArrowLeft}
    onClick={onClick}
    tabIndex={2}
  />
)

export default PreviousButton
