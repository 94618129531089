import React from 'react'

import classMerge from '../../utils/classMerge'
import { FiniteProgressBarProps } from './FiniteProgressBar'
import ProgressBar from './ProgressBar'

export interface StepProgressBarProps extends FiniteProgressBarProps {
  onStepClick?: (stepIndex: number) => void
}

const StepProgressBar = ({
  containerClassName,
  className,
  totalSteps,
  currentStep,
  onStepClick,
}: StepProgressBarProps) => {
  const currentStepPercent = 1 / totalSteps
  const gapsCount = totalSteps - 1

  return (
    <div
      className={classMerge(containerClassName, 'w-full flex space-x-2 relative')}
      style={{ containerType: 'size' }}
      role="progressbar"
    >
      {Array.from(new Array(totalSteps)).map((_, index) => (
        <ProgressBar
          key={`progress-step-${index}`}
          containerClassName={classMerge({ 'hover:cursor-pointer': onStepClick != null })}
          className={className}
          percentage={0}
          onClick={() => onStepClick?.(index)}
        />
      ))}
      <div
        className="absolute !ml-0 will-change-transform"
        style={{
          width: `calc(${currentStepPercent} * (100cqw - ${0.5 * gapsCount}rem))`,
          left: 0,
          transform: `translateX(calc(${currentStep * 100}% + ${0.5 * currentStep}rem))`,
          transition: 'transform 0.2s ease-in-out',
        }}
      >
        <ProgressBar className={classMerge(className)} percentage={100} />
      </div>
    </div>
  )
}

export default StepProgressBar
