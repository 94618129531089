import { Button } from '@packages/sk8/button'
import React from 'react'

export interface SkipButtonProps {
  onClick: () => void
}

const SkipButton = ({ onClick }: SkipButtonProps) => (
  <Button type="button" variant="text" className="ml-auto text-neutral-400" small onClick={onClick} tabIndex={2}>
    Skip
  </Button>
)

export default SkipButton
