import { Button } from '@packages/sk8/button'
import { Input, InputField } from '@packages/sk8/input'
import { animated } from '@react-spring/web'
import React from 'react'

import RegisterSurveySkipButtons from '../RegisterSurveySkipButton'
import { StepProps, companyNameQuestion } from '../types'
import * as Header from './../../fullPageSurvey/Header'
import PreviousButton from './../../fullPageSurvey/PreviousButton'

const CompanyName = ({ style, isAnimating, onPrevious, onNext, goToLast, formikProps }: StepProps) => {
  return (
    <animated.div style={style}>
      <Header.H2>
        <PreviousButton onClick={onPrevious} />
        This wont take long :)
        <RegisterSurveySkipButtons field="companyName" formikProps={formikProps} onSkip={onNext} onSkipAll={goToLast} />
      </Header.H2>
      <Header.H1>
        <div>{companyNameQuestion}</div>
        <div className="text-sm font-normal">Feel free to enter your company name if you don't have a website!</div>
      </Header.H1>

      <div>
        <InputField className="mb-6">
          <Input
            autoFocus={!isAnimating && !formikProps.values.companyName}
            className="!h-[42.5px]"
            id="companyName"
            name="companyName"
            placeholder="wayne-enterprises.com"
            value={formikProps.values.companyName}
            onChange={formikProps.handleChange}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
                onNext()
              }
            }}
          />
        </InputField>
      </div>
      <div className="pt-4">
        <Button type="button" className="w-full " variant="primary" onClick={onNext}>
          Next
        </Button>
      </div>
    </animated.div>
  )
}

export default CompanyName
