import React, { useContext } from 'react'
import { ProductFruits } from 'react-product-fruits'

import TenantContext from 'common/tenant/TenantContext'
import useCurrentUser from 'common/users/hooks/useCurrentUser'

import './OnboardingConfig.css'

const OnboardingConfig = () => {
  const { currentUser } = useCurrentUser()
  const tenant = useContext(TenantContext)

  if (!currentUser) {
    return null
  }

  return (
    <ProductFruits
      key={currentUser.id}
      workspaceCode={PRODUCT_FRUITS_KEY}
      language="en"
      user={{
        username: currentUser.email,
        email: currentUser.email,
        role: tenant ? `${tenant} - ${currentUser.role}` : currentUser.role,
        firstname: currentUser.firstName,
        lastname: currentUser.lastName,
        props: {
          id: currentUser.id,
        },
      }}
    />
  )
}

export default OnboardingConfig
