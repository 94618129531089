import { FullPageModal, useModal } from '@packages/sk8/modal'
import { StepProgressBar } from '@packages/sk8/progress-bar'
import { ToastType, useToast } from '@packages/sk8/toast'
import { ActivityLogType } from '@packages/types'
import { Formik } from 'formik'
import React, { useState } from 'react'
import * as yup from 'yup'

import { trpc } from 'common/hooks/trpc'
import Skater from 'img/skater.svg'

import Stepper from './../fullPageSurvey/Stepper'
import CompanyName from './Questions/CompanyName'
import JobTitle from './Questions/JobTitle'
import LaunchDate from './Questions/LaunchDate'
import Name from './Questions/Name'
import ProductType from './Questions/ProductType'
import SalesStatus from './Questions/SalesStatus'
import TeamSize from './Questions/TeamSize'
import { SurveySubmitValues, SurveyValues, rawSurveyMapping } from './types'

const Steps = [Name, CompanyName, SalesStatus, TeamSize, JobTitle, LaunchDate, ProductType]

const formValidation = yup.object().shape({
  name: yup.string().required('Please enter your first name'),
  otherProductType: yup.string().when('productType', {
    is: 'Other',
    then: schema => schema.required(),
  }),
})

const RegisterSurvey = () => {
  const [step, setStep] = useState(0)
  const { openGenericErrorToast, openToast } = useToast()
  const registerSurveyModal = useModal()
  const utils = trpc.useContext()

  trpc.activityLog.listLatest.useQuery([ActivityLogType.RegisterSurvey], {
    onSuccess: data => {
      if (!data?.length) registerSurveyModal.open()
    },
  })

  const closeModal = () => {
    utils.activityLog.listLatest.invalidate()
    registerSurveyModal.close()
  }

  const { mutate: submitSurvey } = trpc.brand.submitRegisterSurvey.useMutation({
    onSuccess() {
      closeModal()
      openToast('Welcome to Kickflip!', ToastType.success)
    },
    onError() {
      openGenericErrorToast('We could not submit your answers.')
    },
  })

  if (!registerSurveyModal.isVisible) return null

  return (
    <FullPageModal {...registerSurveyModal.modalProps} className="z-[99999999]">
      <div className="h-full w-full flex items-center justify-center overflow-hidden">
        <img src={Skater} className="h-8 w-8 absolute left-10 top-8" />

        <div className="flex flex-col h-full">
          <StepProgressBar totalSteps={Steps.length} currentStep={step} />

          <div className="flex flex-col flex-grow justify-center">
            <div className="w-96 relative overflow-visible">
              <Formik<SurveyValues>
                initialValues={{
                  companyName: '',
                  name: '',
                }}
                validationSchema={formValidation}
                onSubmit={({ otherProductType, ...values }: SurveyValues): void | Promise<any> => {
                  const surveyValues = {
                    ...values,
                  } as SurveySubmitValues

                  if (values.companyName) {
                    const domainMatch = values.companyName.match(/([a-z0-9]+\.)*([a-z0-9]+)\.[a-z]+/)

                    if (domainMatch) {
                      surveyValues.companyName = domainMatch[domainMatch.length - 1]
                      surveyValues.domain = values.companyName
                    }
                  }

                  if (otherProductType) surveyValues.productType = otherProductType

                  const rawValues = rawSurveyMapping.map(({ question, key }) => {
                    return {
                      question,
                      answer: surveyValues[key] ?? '',
                    }
                  })

                  submitSurvey({ values: surveyValues, rawValues })
                }}
              >
                {props => (
                  <form onSubmit={props.handleSubmit}>
                    <Stepper currentStep={step} setStep={setStep} Steps={Steps} stepProps={{ formikProps: props }} />
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </FullPageModal>
  )
}

export default RegisterSurvey
